import React, { Component } from "react";
import {Redirect} from "react-router-dom";
import { HubConnectionBuilder } from "@microsoft/signalr";
import axios from "../../../axios-api";
// const logo = require("../../../styles/img/smallLogo.png");

class topheader extends Component {
  state = {
    RedirectPath: "",
    ncount:0
  };

  componentDidMount() {

    const connection = new HubConnectionBuilder()
      .withUrl(axios.defaults.url + "hubs/roomservice")
      .withAutomaticReconnect()
      .build();

    if (connection) {
      connection.start()
            .then(result => {
                connection.on('ReceiveDashboard', data => {
                    if(data === "1")
                    {
                      let tempcount = this.state.ncount;
                      tempcount++;
                      this.setState({ncount:tempcount})
                    }
                
                });
            })
            .catch(e => console.log('Connection failed: ', e));
    }
    this.setState({ connection: connection });

  }

  Dashboard = () => {
    this.setState({
      RedirectPath: "/dashboard"
    });
  };

  render() {
    if (this.state.RedirectPath !== "") {
      return (
        <Redirect
          to={{
            pathname: this.state.RedirectPath,
            state: {
              FetchData: true,
            },
          }}
        />
      );
    }
    return (
      <>
         <header>
        <nav className="navbar navbar-expand-sm fixed-top">
          <a className="navbar-brand" href="#">
            Al-Raihaan
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">
                  Room service
                </a>
              </li>
            </ul>
            <form className="form-inline my-2 my-lg-0">
              <ul className="navbar-nav rightNav">
              <li className="nav-item dropdown notifications">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img  src={require("../../../images/bell.png")} className="img-fluid" alt="notification icon"/>
                                    {this.state.ncount === 0 ? null:<span className="badge ">{this.state.ncount}</span>}
                                  </button>                
                                <div className="dropdown-menu" >
                                <a className="dropdown-item" href="" style={{cursor:"pointer"}} >There are {this.state.ncount} New/Updated Requests</a>
                                 <a className="dropdown-item" href="" style={{color:"#1295bb",textDecoration:"underline"}}>View Dashbord</a>
                                 {/* {window.location.hash === "#/dashboard" || window.location.hash === "#/" ?
                                 <><a className="dropdown-item" style={{cursor:"pointer"}}>There are {this.state.ncount} New/Updated Requests</a>
                                 <a data-id="1" className="dropdown-item" style={{cursor:"pointer"}} href="">Click to Reload</a>
                               </>:
                                 <><a className="dropdown-item" style={{cursor:"pointer"}} >There are {this.state.ncount} New/Updated Requests</a>
                                 <a className="dropdown-item" href="#/dashboard" style={{cursor:"pointer"}}>View Dashbord</a>
                               </>} */}
                                  </div>
                            </li>
                {/* <li className="nav-item dropdown messages">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={require("../../../images/message.png")}
                      className="img-fluid"
                      alt="message icon"
                    />
                    <span className="badge ">4</span>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#">
                      M1
                    </a>
                    <a className="dropdown-item" href="#">
                      M2
                    </a>
                    <a className="dropdown-item" href="#">
                      M3
                    </a>
                    <a className="dropdown-item" href="#">
                      M4
                    </a>
                  </div>
                </li> */}
                <li className="nav-item dropdown currentUser">
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={require("../../../images/avatar.png")}
                      alt="user icon"
                      className="img-fluid userIcon"
                    />
                    {localStorage.getItem("username")}
                    
                  </button>
                  <div className="dropdown-menu">
                  <a className="dropdown-item">
                     Role - {localStorage.getItem("role2")}
                    </a>
                    {/* <a className="dropdown-item" href="#">
                      Action 1
                    </a>
                    <a className="dropdown-item" href="#">
                      Action 2
                    </a>
                    <a className="dropdown-item" href="#">
                      Action 3
                    </a> */}
                    <a className="dropdown-item" href="#/logout" style={{textDecoration:"underline"}}>Logout</a>
                  </div>
                </li>
                
              </ul>
            </form>
          </div>
        </nav>
      </header>
      </>
    );
  }
}
export default topheader;
