import axios from 'axios';

const instance = axios.create({
     baseURL:"/API/",
    url:"https://roomservice.babjisoftech.com/API/"
  //  baseURL:'https://localhost:44338/',
  //  url:"https://localhost:44338/"
  // baseURL:"https://roomserviceadmin.babjisoftech.com/API/",
  
});

export default instance;