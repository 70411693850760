import React, { Component } from "react";
// import CustomScroll from "react-custom-scroll";
import { NavLink } from "react-router-dom";
import SwitchComponent from "../../../App";
// import classes from "./LeftNavigation.css";
import Aux1 from "../../../hoc/Aux1/Aux1";
import NavigationItems from "../NavigationItems/NavigationItems";

const leftNavigation = (props) => {
  let btnElements = document.querySelectorAll(".sidenav ul li a");
    const removeClass =  function() {btnElements.forEach(btn => {            
        btn.classList.remove('active');          
      });}
  return (
    <Aux1>
      <div className="sideNav">
        <ul className="list-unstyled">
        <NavigationItems isAuthenticated={props.isAuth} />
          {/* <li>
            <a href="index.html">
              <img
                className="img-fluid"
                // src="../../images/dashboard.png"
                src={require("../../../images/dashboard.png")}
                alt="dashboard icon"
              />
              <span>Dashboard</span>
            </a>
          </li>
          <li>
            <a href="room-plan.html">
              <img
                className="img-fluid"
                src={require("../../../images/layout.png")}
                alt="layout icon"
              />
              <span>Layouts</span>
            </a>
          </li> */}
          {/* <li>
            <a href="login.html">
              <img
                className="img-fluid"
                src={require("../../../images/newUser.png")}
                alt="user icon"
              />
              <span>Create User</span>
            </a>
          </li>
          <li>
            <a href="room-plan.html">
              <img
                className="img-fluid"
                src={require("../../../images/layout.png")}
                alt="layout icon"
              />
              <span>Layouts</span>
            </a>
          </li>
          <li>
            <a href="feedback.html">
              <img
                className="img-fluid"
                src={require("../../../images/feedback.png")}
                alt="feedback icon"
              />
              <span>Feedback</span>
            </a>
          </li>
          <li>
            <a href="admin.html">
              <img
                className="img-fluid"
                src={require("../../../images/newUser.png")}
                alt="feedback icon"
              />
              <span>Admin</span>
            </a>
          </li> */}
        </ul>
      </div>
      {/* <aside className="sidenav">
        <div className="crown topCrown" />
          <div className="navContent">
            <ul className="list-unstyled">
              <NavigationItems isAuthenticated={props.isAuth} />
            </ul>
          </div>
        <div className="crown bottomCrown" />
      </aside> */}

      {SwitchComponent}
    </Aux1>
  );
};

export default leftNavigation;
