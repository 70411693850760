import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import dashboard from "../../../../images/dashboard.png";

// const dashboard = require(`../../../../images/dashboard.png`);
class navigationItem extends Component {
// const navigationItem = ( props ) => (
    // CloseNav = () =>{
    //     if(document.getElementsByClassName("navbar-toggler")[0].className !== "navbar-toggler collapsed")
    //     {
    //     document.querySelector('.navbar-toggler').click();
    //     }
    // }
    render() {
        return (
        //     <li>
        //     <a href="index.html">
        //       <img
        //         className="img-fluid"
        //         // src="../../images/dashboard.png"
        //         src={require("../../../images/dashboard.png")}
        //         alt="dashboard icon"
        //       />
        //       <span>Dashboard</span>
        //     </a>
        //   </li>
    //  <li className={(this.props.children.toLowerCase()).replace(/\s/g,'')}>
        <li>
        <NavLink 
            // onClick={()=>this.CloseNav()}
            to={this.props.link}
            exact={this.props.exact}>
                <img
                className="img-fluid"
                src={"../images/"+(this.props.children.toLowerCase()).replace(/\s/g,'') +".png"}
                alt="dashboard icon"
              />
              <span>{this.props.children}</span></NavLink>
            {/* <NavLink className="nav-link" to="/products">Products</NavLink> */}
    </li>
);
        }
    }

export default navigationItem;
