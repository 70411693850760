//import React from 'react';
import React, { Component } from "react";
//import { connect } from "react-redux";

//import Order from '../../components/Order/Order';
//import axios from '../../../axios-api';
import withErrorHandler from "../../../hoc/withErrorHandler/withErrorHandler";
//import * as actions from "../../../store/actions/index";
import Spinner from "../../UI/Spinner/Spinner";
import axios from "../../../axios-api";

//import classes from './NavigationItems.css';
import NavigationItem from "./NavigationItem/NavigationItem";

class navigationItems extends Component {
  state = {
    Navigation: [],
    loading: true,
  };
  componentDidMount () {
    const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
    };
    
    const bodyParameters = {
       key: "value"
    };
    axios.get( '/Navigation/GetLinkAccess',config)
        .then( res => {
            const fetchedNavigationList = [];
            for ( let key in res.data.links ) {
                fetchedNavigationList.push( {
                    ...res.data.links[key],
                    id: key,
                    url:res.data.links[key].url,
                    displayName:res.data.links[key].displayName
                } );
            }
            //dispatch(fetchNavigationSuccess(fetchedNavigationList));
            this.setState({loading: false, Navigation: fetchedNavigationList});
        } )
        .catch( err => {
           // dispatch(fetchNavigationFail(err));
           this.setState({loading: false});
        } );
}

  render() {
    let Navigation = <Spinner />;
    ////Uncomment for build
    if (!this.state.loading) {
      Navigation = this.state.Navigation.map((Navigation) => (
        <NavigationItem
          //link={Navigation.url=='/Dashboard'?'/':Navigation.url}
          link={Navigation.url}
          exact
        >
          {Navigation.displayName}
        </NavigationItem>
      ));
    }
    ////Uncomment for build end
    return (
      ////Uncomment for build
       <>{Navigation}</>
      ////Uncomment for build end

      ////comment on build
      //  <div>
      //     <NavigationItem link="/home">Home</NavigationItem>
      //     <NavigationItem link="/Tags">Tags</NavigationItem>
      //     {/* <NavigationItem link="/OnlineStatus">Online Status</NavigationItem>
      //     <NavigationItem link="/TravelHistory">Travel History</NavigationItem>
      //     <NavigationItem link="/Tools">Tools</NavigationItem> */}
      //  </div>
      ////comment on build end
    );
  }
}
export default withErrorHandler(navigationItems, axios);
